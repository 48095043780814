import React, { Component } from "react";
import { connect } from "react-redux";
import { getAuthenticatedUser } from "../redux/actions/userActions";
// import jwt from 'jsonwebtoken';
import PreLoader from "../components/PreLoader";
import DashboardDefault from "../components/DashboardDefault";

export default function (ComposedClass, i) {
  const token = localStorage.getItem("auth");
  class AuthenticationCheck extends Component {
    state = {
      loading: true,
    };

    async componentDidMount() {
      try {
        if (token === null || !token) return this.props.history.push("/login");
        const result = await this.props.dispatch(getAuthenticatedUser());
        const { payload } = result;

        if (!payload || payload === null || !payload.email) {
          localStorage.removeItem("auth");
          this.props.history.push("/login");
        } else {
          if (!payload.isPhoneVerified) {
            const { phone_temp } = payload;
            return phone_temp
              ? this.props.history.push("/phone/enter-otp")
              : this.props.history.push("/phone");
          }
          if (payload.isAdmin) this.props.history.push("/admin/dashboard");
          this.setState({ loading: false });
        }
      } catch (e) {
        console.log(e);
      }
    }

    componentWillReceiveProps() {
      this.setState({ loading: false });
    }

    render() {
      // console.log(this.props.user ? this.props.user.data : null)
      // console.log(this.state.loading)
      if (this.state.loading) {
        return <DashboardDefault loading />;
      }
      return <ComposedClass {...this.props} />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      user: state.User.user,
    };
  };

  return connect(mapStateToProps)(AuthenticationCheck);
}
