import axios from "axios";
import { toast } from "react-toastify";

export const RegisterUser = async (params, cb, errCb) => {
  // console.log(params)
  try {
    const res = await axios.post("/api/users", params);
    const response = await axios.get(`/api/users/verify/email/${params.email}`);
    // console.log(res)
    toast.success(response.message);
    if (cb) cb();
    window.location = `/verify-email?existing=${false}&email=${params.email}`;
    return {
      type: "REGISTER_USER",
      payload: response,
    };
  } catch (e) {
    if (errCb) errCb();
    toast.error(e.response ? e.response.data.message : null);
  }
};

export const sendOtp = (phone, cb, errCb) => {
  const response = axios
    .post(`/api/users/phone/otp`, { phone })
    .then((res) => {
      toast.success(res.data.message);
      if (cb) cb();
      localStorage.setItem("phone_temp", phone);
      return res.data;
    })
    .catch((e) => {
      if (errCb) errCb(e.response ? e.response.data.message : null);
      toast.error(e.response ? e.response.data.message : null);
      return e.response ? e.response.data : null;
    });
  return {
    type: "SEND_OTP",
    payload: response,
  };
};

export const loginUser = async (params, cb, errCb) => {
  try {
    const response = await axios.post("/api/auth", params);
    const { phone_temp, token, isEmailVerified, isPhoneVerified } =
      response.data;
    localStorage.setItem("auth", token);

    if (!isEmailVerified) {
      const res = await axios.get(`/api/users/verify/email/${params.email}`);
      toast.success(res.message);
      if (cb) cb();
      return (window.location = `/verify-email?existing=${true}&email=${
        params.email
      }`);
    }

    if (!isPhoneVerified) {
      if (phone_temp) {
        localStorage.setItem("phone_temp", phone_temp);
        await axios
          .post(
            `/api/users/phone/otp`,
            { phone: phone_temp },
            { headers: { Authorization: token } }
          )
          .then((res) => res.data);
        return window.location.replace(`phone/enter-otp`);
      } else {
        return window.location.replace(`/phone`);
      }
    }

    if (cb) cb();
    window.location = `/`;
    return {
      type: "LOGIN_USER",
      payload: response,
    };
  } catch (e) {
    if (errCb) errCb();
    toast.error(e.response ? e.response.data.message : null);
    return e.response ? e.response.data : null;
  }
};

export const sendResetPasswordInstruction = async (params, cb, errCb) => {
  try {
    const response = await axios.post("/api/users/forgot-password", params);
    // toast.success(response.data.message)
    if (cb) cb();
    return {
      type: "SEND_RESET_INSTRUCTION",
      payload: response,
    };
  } catch (e) {
    if (errCb) errCb();
    toast.error(e.response ? e.response.data.message : null);
    return e.response ? e.response.data : null;
  }
};

export const resetPassword = async (params, cb, errCb) => {
  try {
    const response = await axios.put("/api/users/reset-password", params);
    toast.success(response.data.message);
    if (cb) cb();
    // setTimeout(() =>{
    window.location = `/login`;
    // },3000)
    return {
      type: "RESET_PASSWORD",
      payload: response,
    };
  } catch (e) {
    if (errCb) errCb();
    toast.error(e.response ? e.response.data.message : null);
    return e.response ? e.response.data : null;
  }
};

export const updateUserProfile = async (params, cb, errCb) => {
  try {
    const response = await axios.put("/api/users/profile", params);
    toast.success(response.data.message);
    if (cb) cb();

    return {
      type: "UPDATE_USER_PROFILE",
      payload: response,
    };
  } catch (e) {
    if (errCb) errCb();
    toast.error(e.response ? e.response.data.message : "An error occured!");
    return e.response ? e.response.data : null;
  }
};

export const addAccountDetails = async (params, cb, errCb) => {
  try {
    const response = await axios.post("/api/users/bank", params);
    toast.success(response.data.message);
    if (cb) cb();

    return {
      type: "ADD_ACCOUNT_DETAILS",
      payload: response,
    };
  } catch (e) {
    if (errCb) errCb();
    toast.error(e.response ? e.response.data.message : "An error occured!");
    return e.response ? e.response.data : null;
  }
};

export const deleteAccountDetails = async (cb, errCb) => {
  try {
    const response = await axios.delete("/api/users/bank");
    toast.success(response.data.message);
    if (cb) cb();

    return {
      type: "DELETE_ACCOUNT_DETAILS",
      payload: response,
    };
  } catch (e) {
    if (errCb) errCb();
    toast.error(e.response ? e.response.data.message : "An error occured!");
    return e.response ? e.response.data : null;
  }
};

export const changeUserPassword = async (params, cb, errCb) => {
  try {
    const response = await axios.put("/api/users/change-password", params);
    toast.success(response.data.message);
    if (cb) cb();

    return {
      type: "CHANGE_USER_PASSWORD",
      payload: response,
    };
  } catch (e) {
    if (errCb) errCb();
    toast.error(e.response ? e.response.data.message : "An error occured!");
    return e.response ? e.response.data : null;
  }
};

export const SendEmailVerifyLink = (email, cb, errCb) => {
  const response = axios
    .get(`/api/users/verify/email/${email}`)
    .then((res) => {
      console.log(res.data);
      toast.success(res.data.message);
      if (cb) cb();
      return res.data;
    })
    .catch((e) => {
      if (errCb) errCb();
      toast.error(e.response ? e.response.data.message : null);
      return e.response ? e.response.data : null;
    });
  return {
    type: "EMAIL_VERIFY_LINK",
    payload: response,
  };
};

export const VerifyEmail = (params, cb, errCb) => {
  const response = axios
    .put(`/api/users/verify/email/`, params)
    .then((res) => {
      console.log(res.data);
      toast.success(res.data.message);
      if (cb) cb();
      return res.data;
    })
    .catch((e) => {
      if (errCb) errCb(e.response ? e.response.data.message : null);
      toast.error(e.response ? e.response.data.message : null);
      return e.response ? e.response.data : null;
    });
  return {
    type: "VERIFY_EMAIL",
    payload: response,
  };
};

export const verifyOtp = (params, phone, email, cb, errCb) => {
  const response = axios
    .post(`/api/users/phone/verify`, params)
    .then((res) => {
      if (cb) cb();
      return res.data;
    })
    .catch((e) => {
      if (errCb) errCb(e.response ? e.response.data.message : null);
      toast.error(e.response ? e.response.data.message : null);
      return e.response ? e.response.data : null;
    });
  return {
    type: "SEND_OTP",
    payload: response,
  };
};

export const getAuthenticatedUser = (params, cb, errCb) => {
  const respose = axios
    .get("/api/users/profile")
    .then((res) => {
      // console.log(res.data)
      if (cb) cb();
      return res.data;
    })
    .catch((e) => {
      if (errCb) errCb();
      // toast.error(e.response ? e.response.data.message : null)
      return e.response ? e.response.data : null;
    });
  return {
    type: "GET_AUTHENTICATED_USER",
    payload: respose,
  };
};

export const getAllUsers = (cb, errCb) => {
  const response = axios
    .get("/api/users")
    .then((res) => {
      // console.log(res.data)
      if (cb) cb();
      return res.data;
    })
    .catch((e) => {
      if (errCb) errCb();
      toast.error(e.response ? e.response.data.message : null);
      return e.response ? e.response.data : null;
    });
  return {
    type: "GET_ALL_USERS",
    payload: response,
  };
};

export const deleteUser = (id, cb, errCb) => {
  const respose = axios
    .delete(`/api/users/${id}`)
    .then((res) => {
      // console.log(res.data)
      if (cb) cb();
      return res.data;
    })
    .catch((e) => {
      if (errCb) errCb();
      toast.error(e.response ? e.response.data.message : null);
      return e.response ? e.response.data : null;
    });
  return {
    type: "DELETE_USER",
    payload: respose,
  };
};

export const getDashboardStats = (id, cb, errCb) => {
  const respose = axios
    .get(`/api/users/stats/dashboard-stats`)
    .then((res) => {
      // console.log(res.data)
      if (cb) cb();
      return res.data;
    })
    .catch((e) => {
      if (errCb) errCb();
      toast.error(e.response ? e.response.data.message : null);
      return e.response ? e.response.data : null;
    });
  return {
    type: "FETCH_DASHBOARD_STATS",
    payload: respose,
  };
};

export const getAdminDashboardStats = (id, cb, errCb) => {
  const respose = axios
    .get(`/api/users/admin/stats/dashboard-stats`)
    .then((res) => {
      if (cb) cb();
      return res.data;
    })
    .catch((e) => {
      if (errCb) errCb();
      toast.error(e.response ? e.response.data.message : null);
      return e.response ? e.response.data : null;
    });
  return {
    type: "FETCH_ADMIN_DASHBOARD_STATS",
    payload: respose,
  };
};
