
export default function(state={},action){
    switch(action.type){
        case "REGISTER_USER":
            return {...state, addUser:action.payload}
        
        case "LOGIN_USER":
            return {...state, user:action.payload}

        case "SEND_RESET_INSTRUCTION":
            return {...state, user:action.payload}

        case "RESET_PASSWORD":
            return {...state, user:action.payload}

        case "UPDATE_USER_PROFILE":
            return {...state, update_profile:action.payload}

        case "ADD_ACCOUNT_DETAILS":
            return {...state, add_bank:action.payload}

        case "DELETE_ACCOUNT_DETAILS":
            return {...state, delete_bank:action.payload}
            
        case "CHANGE_USER_PASSWORD":
            return {...state, change_password:action.payload}

        case "EMAIL_VERIFY_LINK":
            return {...state, verify_email:action.payload}

        case "VERIFY_EMAIL":
            return {...state, verify_email:action.payload}

        case "SEND_OTP":
            return {...state, otp:action.payload}

        case "VERIFY_OTP":
            return {...state, otp:action.payload}

        case "GET_AUTHENTICATED_USER":
            return {...state, user:action.payload}

        case "GET_ALL_USERS":
            return {...state, users:action.payload}

        case "DELETE_USER":
            return {...state, delete_user:action.payload}

        case "FETCH_DASHBOARD_STATS":
            return {...state, dashboard_stats:action.payload}

        case "FETCH_ADMIN_DASHBOARD_STATS":
            return {...state, admin_dashboard_stats:action.payload}

        default:
            return state
    }
}