import React,{ Component} from 'react';
import {connect} from 'react-redux';
import {getAuthenticatedUser} from '../redux/actions/userActions';
// import jwt from 'jsonwebtoken';
import PreLoader from '../components/PreLoader'


export default function(ComposedClass, i){
    const token = localStorage.getItem('auth')
    class AuthenticationCheck extends Component{
        state={
            loading:true
        }

        async componentDidMount(){
           
            try{
                
                if(token === null || !token) return this.props.history.push('/')

                const result = await this.props.dispatch(getAuthenticatedUser())
                // console.log(result)
                if(!result.payload || result.payload === null || !result.payload.email){
                    localStorage.removeItem('auth')
                    
                }else{
                    if(!result.payload.isAdmin){
                        this.props.history.push('/')
                    }
                    this.setState({loading:false})
                }
            }
            catch(e){
                console.log(e)
            }

            
        }

        componentWillReceiveProps(){
            this.setState({loading:false})

        }

        
        render(){
            // console.log(this.props.user ? this.props.user.data : null)
            // console.log(this.state.loading)
            if(this.state.loading){
                return <PreLoader/>
            }
            return <ComposedClass {...this.props}/>
           
        }
    }

    const mapStateToProps = state =>{
        return {
            user:state.User.user
        }
    }

    return connect(mapStateToProps)(AuthenticationCheck)
}