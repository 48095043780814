import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import Routes from "./routes";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import promiseMiddleware from "redux-promise";
import "react-toastify/dist/ReactToastify.css";
// import App from './containers/app.js';
import reducers from "./redux/reducers";

const token = localStorage.getItem("auth");
axios.defaults.headers.common["Authorization"] = `${token}`;
// axios.defaults.baseURL = "http://localhost:5000" || "https://myecurrencyng-backend.herokuapp.com";
axios.defaults.baseURL =
  process.env.REACT_APP_API_URL ||
  "https://myecurrencyng-backend.herokuapp.com";

const createStoreWithMiddleware =
  applyMiddleware(promiseMiddleware)(createStore);

ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers)}>
    <Routes />
  </Provider>,
  // <Routes/>
  document.getElementById("root")
);
