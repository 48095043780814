
export default function(state={},action){
    switch(action.type){
        case "POST_ORDER":
            return {...state, order:action.payload}
        
        case "UPDATE_ORDER":
            return {...state, update_order:action.payload}

        case "GET_ALL_TRANSACTIONS":
            return {...state, orders:action.payload}

        case "GET_ALL_ORDERS":
            return {...state, orders:action.payload}

        default:
            return state
    }
}