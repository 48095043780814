export default function(state={},action){
    switch(action.type){
        case "GET_ADMIN_WALLET":
            return {...state, wallet:action.payload}

        case "CREDIT_ADMIN_WALLET":
            return {...state, wallet:action.payload}
            
        case "DISBURSE_FUND":
            return {...state, wallet:action.payload}

        case "VERIFY_BANK_ACCOUNT":
            return {...state, account:action.payload}

        case "WITHDRAW_FUND":
            return {...state, withdraw_fund:action.payload}

        case "GET_PAYMENT_REQUEST":
            return {...state, payment_request:action.payload}
        
            
        default:
            return state
    }
}