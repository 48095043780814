import React from 'react';
import styled from 'styled-components';
import {NavLink, Link} from 'react-router-dom';
import SideBar from '../SideBar';
import ContentLoader, { Facebook } from "react-content-loader";
import { render } from 'react-dom';
import { connect } from 'react-redux';

const Wrapper = styled.div`
    .dashboard-content{
        .top-bar{
            .search{
                height: 48px;
                border: 0.5px solid #E5E5E5;
                box-sizing: border-box;
                border-radius: 5px;
                img{

                }
                input{
                    height:100%;
                    border:none;
                    outline:none;
                    width:100%;
                    font-size: 14px;
                    line-height: 17px;
                    color: #505050;
                    ::placeholder{
                        font-size: 14px;
                        line-height: 17px;
                        color: #BBBBBB;
                    }
                }
            }
        }
        .notification{
            position: relative;
            img{

            }
            .notify{
                position: absolute;
                width: 10px;
                height: 10px;
                top: 0px;
                right:0;
                border-radius:50%;
                background: #F79521;
            }
        }
        .user{

        }
    }
`;

function Index({children, loading, user}) {

    const renderContentLoader = () =>{
        return <div className="" style={{height:'calc(100vh - 150px)'}} >
            <ContentLoader 
                width={"100%"}
                height={"100%"}
            >
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
        </div>
    }
    // console.log(user)
    return (
        <Wrapper className="">
            <div className="row w-100 mx-0 mx-lg-n4">
                <SideBar />
                <div className="col-lg-9 pl-0 pl-lg-3 pr-0 ml-auto">
                    <div className="dashboard-content container pt-lg-5 pt-4 pb-4">
                        <div className="top-bar mt-5 mt-lg-0 pt-md-4 pt-3 pt-lg-0 mt-lg-0 row align-items-center mb-4">
                            <div className="col-lg-6 col-9">
                                <div className="search d-flex align-itens-center px-3">
                                    <span className="d-flex align-itens-center"><img src={require('../../assets/images/search.svg')} alt="" className="mr-3"/></span>
                                    <input type="search" placeholder="search"/>
                                </div>
                            </div>

                            <div className="col-lg-6 col-3">
                                <div className="d-flex align-items-center justify-content-end">
                                    <div className="notification mr-md-4 mr-3 pointer">
                                        <img src={require('../../assets/images/bell.svg')} alt="" className=""/>
                                        {/* <div className="notify"></div> */}
                                    </div>
                                    <Link to="/settings">
                                        <div className="d-flex align-items-center justify-content-center" style={{width:'30px', height:'30px', borderRadius:'50%', background:'#F79521'}}>
                                            <p className="mb-0 text-white text-uppercase font-weight-bold">{user ? user.username ? user.username[0] :null : null}</p>
                                        </div>
                                    </Link>
                                    {/* <img src={require('../../assets/images/user.jpg')} alt="" className="user"/> */}
                                </div>
                            </div>
                        </div>
                        <div className="body  pt-2">
                            {
                                loading
                                ? renderContentLoader()
                                :children
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

function mapStateToProps(state){
    return{
        user:state.User.user
    }
}
export default connect(mapStateToProps)(Index)