import React from "react";
import Loadable from "react-loadable";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Layout from "./hoc/layout";
import Auth from "./hoc/auth";
import AdminAuth from "./hoc/adminAuth";
import SuperAdminAuth from "./hoc/superAdminAuth";
import PreLoader from "./components/PreLoader";
import DashboardDefaultWithLoading from "./components/DashboardDefaultWithLoading/index";

// const Coming = Loadable({
//     loader: () => import('./pages/Coming/index'),
//     loading: PreLoader,
// });
const Register = Loadable({
  loader: () => import("./pages/Register"),
  loading: PreLoader,
});

const EmailVerification = Loadable({
  loader: () => import("./pages/EmailVerification"),
  loading: PreLoader,
});

const VerifyEmail = Loadable({
  loader: () => import("./pages/VerifyEmail"),
  loading: PreLoader,
});

const ForgotPassword = Loadable({
  loader: () => import("./pages/ForgotPassword"),
  loading: PreLoader,
});

const ResetPassword = Loadable({
  loader: () => import("./pages/ResetPassword"),
  loading: PreLoader,
});

const Phone = Loadable({
  loader: () => import("./pages/Phone"),
  loading: PreLoader,
});

const EnterOtp = Loadable({
  loader: () => import("./pages/EnterOtp"),
  loading: PreLoader,
});

const Login = Loadable({
  loader: () => import("./pages/Login"),
  loading: PreLoader,
});

const Dashboard = Loadable({
  loader: () => import("./pages/Dashboard/Index"),
  loading: DashboardDefaultWithLoading,
});
const Product = Loadable({
  loader: () => import("./pages/Dashboard/Product/Index"),
  loading: DashboardDefaultWithLoading,
});

const SellCard = Loadable({
  loader: () => import("./pages/Dashboard/Product/SellCard"),
  loading: DashboardDefaultWithLoading,
});

const SellCoin = Loadable({
  loader: () => import("./pages/Dashboard/Product/SellCoin"),
  loading: DashboardDefaultWithLoading,
});

const ProvideCard = Loadable({
  loader: () => import("./pages/Dashboard/Product/ProvideCard"),
  loading: DashboardDefaultWithLoading,
});

const ProvideCoin = Loadable({
  loader: () => import("./pages/Dashboard/Product/ProvideCoin"),
  loading: DashboardDefaultWithLoading,
});

const Transactions = Loadable({
  loader: () => import("./pages/Dashboard/Transactions"),
  loading: DashboardDefaultWithLoading,
});

const Settings = Loadable({
  loader: () => import("./pages/Dashboard/Settings"),
  loading: DashboardDefaultWithLoading,
});

const Test = Loadable({
  loader: () => import("./pages/Test"),
  loading: DashboardDefaultWithLoading,
});

// const Sponsor = Loadable({
//     loader: () => import('./pages/Sponsor'),
//     loading: PreLoader,
// });

const AdminLogin = Loadable({
  loader: () => import("./pages/Admin/Login"),
  loading: PreLoader,
});

const AdminDashboard = Loadable({
  loader: () => import("./pages/Admin/Dashboard"),
  loading: PreLoader,
});

const AdminOrders = Loadable({
  loader: () => import("./pages/Admin/Orders"),
  loading: PreLoader,
});

const AdminProducts = Loadable({
  loader: () => import("./pages/Admin/Products"),
  loading: PreLoader,
});

const AdminAddProducts = Loadable({
  loader: () => import("./pages/Admin/Products/add"),
  loading: PreLoader,
});

const AdminRates = Loadable({
  loader: () => import("./pages/Admin/Rates"),
  loading: PreLoader,
});

const AdminAddRates = Loadable({
  loader: () => import("./pages/Admin/Rates/add"),
  loading: PreLoader,
});

const AdminPayment = Loadable({
  loader: () => import("./pages/Admin/Payment"),
  loading: PreLoader,
});

// const AdminSponsor = Loadable({
//     loader: () => import('./pages/Admin/Sponsor'),
//     loading: PreLoader,
// });

// const ManageAdmins = Loadable({
//     loader: () => import('./pages/Admin/ManageAdmins'),
//     loading: PreLoader,
// });

// const AddNewAdmin = Loadable({
//     loader: () => import('./pages/Admin/ManageAdmins/Add'),
//     loading: PreLoader,
// });

const Routes = (props) => {
  return (
    <Layout>
      <BrowserRouter>
        <Switch>
          {/* <Route path="/admin/manage-admins/add" component={SuperAdminAuth(AddNewAdmin)}/> */}
          {/* <Route path="/admin/manage-admins" component={SuperAdminAuth(ManageAdmins)}/> */}
          <Route path="/admin/payments" component={AdminAuth(AdminPayment)} />
          <Route path="/admin/rates/add" component={AdminAuth(AdminAddRates)} />
          <Route path="/admin/rates" component={AdminAuth(AdminRates)} />
          <Route
            path="/admin/products/add"
            component={AdminAuth(AdminAddProducts)}
          />
          <Route path="/admin/products" component={AdminAuth(AdminProducts)} />
          <Route path="/admin/orders" component={AdminAuth(AdminOrders)} />
          {/*<Route path="/admin/sponsors" component={SuperAdminAuth(AdminSponsor)}/>*/}
          <Route
            path="/admin/dashboard"
            component={AdminAuth(AdminDashboard)}
          />
          <Route path="/admin/login" component={AdminLogin} />
          {/* <Route path="/sponsor" component={Sponsor}/> */}
          {/* <Route path="/index" component={Index}/> */}
          <Route path="/test" component={Test} />
          <Route path="/phone/enter-otp" component={EnterOtp} />
          <Route path="/phone" component={Phone} />
          <Route path="/email/verify/:token" component={VerifyEmail} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/verify-email" component={EmailVerification} />
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/settings" component={Auth(Settings)} />
          <Route path="/transactions" component={Auth(Transactions)} />
          <Route path="/product/card/sell/:id" component={Auth(ProvideCard)} />
          <Route path="/product/coin/sell/:id" component={Auth(ProvideCoin)} />
          <Route path="/product/card" component={Auth(SellCard)} />
          <Route path="/product/coin" component={Auth(SellCoin)} />
          <Route path="/product" component={Auth(Product)} />
          <Route path="/" component={Auth(Dashboard)} />
        </Switch>
      </BrowserRouter>
    </Layout>
  );
};

export default Routes;
