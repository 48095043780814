export default function (state = {}, action) {
  // console.log(action)
  switch (action.type) {
    case "ADD_SPONSOR":
      return { ...state, sponsor: action.payload };

    case "GET_ALL_CARDS":
      return { ...state, cards: action.payload };

    case "GET_ONE_PRODUCT":
      return { ...state, product: action.payload };

    case "GET_SINGLE_PRODUCT_RATE":
      return { ...state, rates: action.payload };

    case "GET_ALL_COINS":
      return { ...state, coins: action.payload };

    case "ADD_PRODUCT":
      return { ...state, product: action.payload };

    case "GET_ALL_PRODUCT":
      return { ...state, products: action.payload };

    case "GET_ALL_CRYPTO":
      return { ...state, coins: action.payload };

    case "DELETE_PRODUCT":
      return { ...state, delete_product: action.payload };

    case "UPDATE_PRODUCT":
      return { ...state, update_product: action.payload };

    case "GET_ALL_RATES":
      return { ...state, rates: action.payload };

    case "CLEAR_PRODUCT_RATE":
      return { ...state, rates: [] };

    case "DELETE_RATE":
      return { ...state, delete_rate: action.payload };

    case "UPDATE_RATE":
      return { ...state, update_rate: action.payload };
    default:
      return state;
  }
}
