import React, { useState } from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";

const Wrapper = styled.div`
  .sidebar {
    padding: 50px;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    background: #fffaf5;
    height: 100vh;
    .logo {
    }
    ul {
      width: fit-content;
      li {
        // font-weight: normal;
        // font-size: 14px;
        // line-height: 17px;
        // color: #BBBBBB;
        // transition:all ease .3s;
        // :hover{
        //     color: #F79521;
        // }
      }
      a {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #bbbbbb;
        transition: all ease 0.3s;
        .menu-icon {
          fill: #bbbbbb;
          transition: all ease 0.3s;
        }
        :hover {
          color: #f79521;
          .menu-icon {
            fill: #f79521;
          }
        }
      }
      .active {
        color: #f79521;
        .menu-icon {
          fill: #f79521;
        }
      }
      img {
      }
    }
    .links {
      z-index: 200;
      position: relative;
    }
  }
  @media (max-width: 991px) {
    .sidebar {
      z-index: 1000;
      width: 100%;
      bottom: 0;
      padding: 25px 50px;
      height: 80px;
      background: ${(props) => (props.nav ? "#FFFAF5" : "#fffaf575")};
      backdrop-filter: ${(props) => (props.nav ? "blur(0px)" : "blur(5px)")};
      transition: all ease 0.3s;

      .menu {
        width: 40px;
      }
      .cancel {
        width: 30px;
      }
    }
    .sidebar.show {
      height: 100%;
    }
  }
  @media (max-width: 767px) {
    .sidebar {
      padding: 20px 20px;
      height: 70px;
      .menu {
        width: 30px;
      }
      .cancel {
        width: 20px;
      }
    }
    .sidebar.show {
      height: 100%;
    }
  }
`;
export default function Index() {
  const [nav, setNav] = useState(false);
  const [index, setIndex] = useState(false);

  const logout = () => {
    localStorage.removeItem("auth");
    window.location = "/login";
  };

  return (
    <Wrapper nav={nav} className="">
      <div className={`col-lg-3 sidebar ${nav ? "show" : ""} `}>
        <div className="">
          <div className="d-flex align-items-center justify-content-between  mb-5">
            <Link to="">
              <img
                src={require("../../assets/images/logo-3.svg")}
                alt=""
                className="logo d-block mx-lg-auto mr-0"
              />
            </Link>
            <div className="d-lg-none">
              {!nav ? (
                <img
                  src={require("../../assets/images/menu.svg")}
                  alt=""
                  className="menu pointer"
                  onClick={() => setNav(true)}
                />
              ) : (
                <img
                  src={require("../../assets/images/cancel.svg")}
                  alt=""
                  className="cancel pointer"
                  onClick={() => setNav(false)}
                />
              )}
            </div>
          </div>

          <ul className="m-0 pl-0 pt-lg-3 links mx-lg-auto mr-0">
            <NavLink
              to="/"
              activeClassName="active"
              className={`pb-2 mb-4 d-block ${index ? "active" : ""}`}
              isActive={(match, location) => {
                setIndex(match.isExact);
              }}
            >
              <li className="d-flex align-items-center">
                <div className="mr-3">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.25 7.75H6.25V0.25H0.25V7.75ZM0.25 13.75H6.25V9.25H0.25V13.75ZM7.75 13.75H13.75V6.25H7.75V13.75ZM7.75 0.25V4.75H13.75V0.25H7.75Z"
                      fill="#F79521"
                      className=" menu-icon"
                    />
                  </svg>
                </div>
                <span>Dashboard</span>
              </li>
            </NavLink>

            <NavLink
              to="/product"
              activeClassName="active"
              className="pb-2 mb-4 d-block"
            >
              <li className="d-flex align-items-center">
                <div className="mr-3">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0623 7.87511L15.9638 8.41586C16.0194 8.44916 16.0654 8.4963 16.0974 8.55269C16.1293 8.60908 16.1461 8.67279 16.1461 8.73761C16.1461 8.80243 16.1293 8.86615 16.0974 8.92254C16.0654 8.97893 16.0194 9.02607 15.9638 9.05936L9.00001 13.2376L2.03626 9.05936C1.98065 9.02607 1.93461 8.97893 1.90265 8.92254C1.87068 8.86615 1.85388 8.80243 1.85388 8.73761C1.85388 8.67279 1.87068 8.60908 1.90265 8.55269C1.93461 8.4963 1.98065 8.44916 2.03626 8.41586L2.93776 7.87511L9.00001 11.5126L15.0623 7.87511ZM15.0623 11.4001L15.9638 11.9409C16.0194 11.9742 16.0654 12.0213 16.0974 12.0777C16.1293 12.1341 16.1461 12.1978 16.1461 12.2626C16.1461 12.3274 16.1293 12.3911 16.0974 12.4475C16.0654 12.5039 16.0194 12.5511 15.9638 12.5844L9.38626 16.5309C9.26961 16.6009 9.1361 16.638 9.00001 16.638C8.86393 16.638 8.73041 16.6009 8.61376 16.5309L2.03626 12.5844C1.98065 12.5511 1.93461 12.5039 1.90265 12.4475C1.87068 12.3911 1.85388 12.3274 1.85388 12.2626C1.85388 12.1978 1.87068 12.1341 1.90265 12.0777C1.93461 12.0213 1.98065 11.9742 2.03626 11.9409L2.93776 11.4001L9.00001 15.0376L15.0623 11.4001ZM9.38551 0.981863L15.9638 4.92836C16.0194 4.96166 16.0654 5.0088 16.0974 5.06519C16.1293 5.12158 16.1461 5.18529 16.1461 5.25011C16.1461 5.31493 16.1293 5.37865 16.0974 5.43504C16.0654 5.49143 16.0194 5.53857 15.9638 5.57186L9.00001 9.75011L2.03626 5.57186C1.98065 5.53857 1.93461 5.49143 1.90265 5.43504C1.87068 5.37865 1.85388 5.31493 1.85388 5.25011C1.85388 5.18529 1.87068 5.12158 1.90265 5.06519C1.93461 5.0088 1.98065 4.96166 2.03626 4.92836L8.61376 0.981863C8.73041 0.911781 8.86393 0.874756 9.00001 0.874756C9.1361 0.874756 9.26961 0.911781 9.38626 0.981863H9.38551Z"
                      fill="#BBBBBB"
                      className="mr-3 menu-icon"
                    />
                  </svg>
                </div>
                <span>Products</span>
              </li>
            </NavLink>

            <NavLink
              to="/transactions"
              activeClassName="active"
              className="pb-2 mb-4 d-block"
            >
              <li className="d-flex align-items-center ">
                <div className="mr-3">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.25 1.53748V9.74998H15.4625C15.0868 13.5397 11.8888 16.5 8 16.5C3.85775 16.5 0.5 13.1422 0.5 8.99998C0.5 5.11123 3.46025 1.91323 7.25 1.53748ZM8.75 0.407227C12.9148 0.764977 16.2343 4.08523 16.5928 8.24998H8.75V0.407227Z"
                      fill="#BBBBBB"
                      className="mr-3 menu-icon"
                    />
                  </svg>
                </div>
                <span>Transactions</span>
              </li>
            </NavLink>

            <NavLink
              to="/settings"
              activeClassName="active"
              className="pb-2 mb-4 d-block"
            >
              <li className="d-flex align-items-center">
                <div className="mr-3">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.4655 0.657539C7.4772 0.445389 8.52185 0.444879 9.53375 0.656039C9.60116 1.101 9.76799 1.52506 10.0218 1.89667C10.2757 2.26829 10.61 2.57792 11 2.80254C11.3897 3.02794 11.8251 3.16259 12.274 3.19648C12.7229 3.23037 13.1737 3.16263 13.5928 2.99829C14.2815 3.76933 14.8032 4.67457 15.125 5.65704C14.7735 5.93797 14.4898 6.2945 14.2951 6.70013C14.1003 7.10576 13.9995 7.55007 14 8.00004C14 8.94804 14.4395 9.79329 15.1265 10.343C14.8028 11.3247 14.2804 12.2292 13.592 13.0003C13.173 12.8361 12.7224 12.7684 12.2737 12.8023C11.8249 12.8362 11.3896 12.9708 11 13.196C10.6103 13.4206 10.2763 13.73 10.0226 14.1014C9.76886 14.4727 9.60204 14.8964 9.5345 15.341C8.52291 15.5537 7.47826 15.5547 6.46625 15.344C6.39916 14.8987 6.23248 14.4742 5.97862 14.1021C5.72476 13.7301 5.39025 13.4201 5 13.1953C4.61028 12.97 4.17478 12.8355 3.7259 12.8017C3.27701 12.768 2.82629 12.8358 2.40725 13.0003C1.71845 12.229 1.19676 11.3235 0.875005 10.3408C1.2263 10.06 1.50985 9.70372 1.70461 9.29837C1.89936 8.89302 2.00033 8.449 2 7.99929C2.00037 7.5492 1.89928 7.10482 1.70426 6.69918C1.50923 6.29354 1.22527 5.93707 0.873505 5.65629C1.19726 4.67463 1.71959 3.77011 2.408 2.99904C2.82699 3.16326 3.27761 3.23094 3.72635 3.19705C4.17509 3.16316 4.61043 3.02857 5 2.80329C5.38966 2.57874 5.72374 2.26931 5.97745 1.89797C6.23115 1.52663 6.39797 1.10292 6.4655 0.658289V0.657539ZM8 10.25C8.59674 10.25 9.16904 10.013 9.59099 9.59103C10.013 9.16907 10.25 8.59678 10.25 8.00004C10.25 7.4033 10.013 6.83101 9.59099 6.40905C9.16904 5.98709 8.59674 5.75004 8 5.75004C7.40327 5.75004 6.83097 5.98709 6.40901 6.40905C5.98706 6.83101 5.75 7.4033 5.75 8.00004C5.75 8.59678 5.98706 9.16907 6.40901 9.59103C6.83097 10.013 7.40327 10.25 8 10.25Z"
                      fill="#BBBBBB"
                      className="mr-3 menu-icon"
                    />
                  </svg>
                </div>

                <span>Settings</span>
              </li>
            </NavLink>

            {/* https://www.tidio.com/talk/t6rusuautmgrdptipy99auqun9w8ucza */}
            <a
              href="https://tawk.to/chat/643349814247f20fefeab3e9/1gtk5e8lv"
              activeClassName="active"
              className="pb-2 mb-4 d-block"
              target="_blank"
            >
              <li className="d-flex align-items-center">
                <div className="mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="18"
                    height="18"
                    x="0"
                    y="0"
                    viewBox="0 0 512 512"
                  >
                    <g>
                      <path
                        d="M136,210c-24.814,0-45,20.186-45,45v122c0,24.814,20.186,45,45,45c24.814,0,45-20.186,45-45V255    C181,230.186,160.814,210,136,210z"
                        fill="#bbbbbb"
                        data-original="#000000"
                        className="mr-3 menu-icon"
                      />
                    </g>
                    <g>
                      <path
                        d="M61,255c0-4.327,0.571-8.507,1.278-12.634C44.2,248.209,31,265.001,31,285v62c0,19.999,13.2,36.791,31.278,42.634    C61.571,385.507,61,381.327,61,377V255z"
                        fill="#bbbbbb"
                        data-original="#000000"
                        className="mr-3 menu-icon"
                      />
                    </g>
                    <g>
                      <path
                        d="M376,210c-24.814,0-45,20.186-45,45v122c0,24.814,20.186,45,45,45c4.51,0,8.782-0.868,12.892-2.108    C383.308,438.401,366.305,452,346,452h-47.763c-6.213-17.422-22.707-30-42.237-30c-24.814,0-45,20.186-45,45    c0,24.814,20.186,45,45,45c19.53,0,36.024-12.578,42.237-30H346c41.353,0,75-33.647,75-75v-30V255    C421,230.186,400.814,210,376,210z"
                        fill="#bbbbbb"
                        data-original="#000000"
                        className="mr-3 menu-icon"
                      />
                    </g>
                    <g>
                      <path
                        d="M449.722,242.366C450.429,246.493,451,250.673,451,255v122c0,4.327-0.571,8.507-1.278,12.634    C467.8,383.791,481,366.999,481,347v-62C481,265.001,467.8,248.209,449.722,242.366z"
                        fill="#bbbbbb"
                        data-original="#000000"
                        className="mr-3 menu-icon"
                      />
                    </g>
                    <g>
                      <path
                        d="M256,0C131.928,0,31,100.928,31,225v0.383c8.937-6.766,19.277-11.717,30.687-13.934C68.698,110.251,153.054,30,256,30    s187.302,80.251,194.313,181.448c11.409,2.217,21.749,7.169,30.687,13.934V225C481,100.928,380.072,0,256,0z"
                        fill="#bbbbbb"
                        data-original="#000000"
                        className="mr-3 menu-icon"
                      />
                    </g>
                  </svg>
                </div>

                <span>Customer care</span>
              </li>
            </a>
          </ul>

          <div className="mt-n5 mb-3">
            <img
              src={require("../../assets/images/step-vector.svg")}
              alt=""
              className=""
            />
          </div>

          <ul className="m-0 p-0 mx-lg-auto mr-0">
            <li className="d-flex align-items-center pointer" onClick={logout}>
              <div className="mr-3">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM4.25 7.25V5L0.5 8L4.25 11V8.75H10.25V7.25H4.25Z"
                    fill="#BBBBBB"
                    className="mr-3 menu-icon"
                  />
                </svg>
              </div>
              <span>Logout</span>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}
