import {combineReducers}  from 'redux';
import User from './userReducer';
import Product from './productReducer';
import Order from './orderReducers'
import Finance from './financeReducer'
// import Participant from './participantReducer'

const rootReducer = combineReducers({
    User,
    Product,
    Order,
    Finance
})

export default rootReducer