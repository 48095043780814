import React from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';

const Wrapper = styled.div`
    height:100vh;
    background: #fbfbfb;
    div{
        height:auto !important;
    }
`;

export default function Index() {
    return (
        <Wrapper className="d-flex align-items-center justify-content-center">
            <img src={require('../../assets/images/logo.gif')} alt="" className="h-100"/>
            {/* <ReactLoading type={"cylon"} color={'#fff'}  width={'50%'}  /> */}
        </Wrapper>
    )
}
